import React from "react";

// Customizable Area Start
import { styled, Typography } from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        <Heading>Terms and Conditions</Heading>
        <Divider />
        <Label dangerouslySetInnerHTML={{ __html: this.props.privacyText }} />
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const Heading = styled("div")({
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "30px 0px 25px",
});

const Label = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.bold,
  lineHeight: "24px",
  marginBottom: "10px"
});
// Customizable Area End
