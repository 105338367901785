import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core";
import { action, back, deleteButton, location } from "./assets";
import Header from "../../../blocks/landingpage/src/Header.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomMenu from "../../../components/src/CustomMenuItem.web";
// Customizable Area End

import AddressListWebController, { Props } from "./AddressListWebController";

export default class AddressList extends AddressListWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} />
        <Main>
          <Back
            data-test-id="handleNavigation"
            onClick={() =>
              this.handleNavigation("Customisableuserprofiles")
            }
            src={back}
          ></Back>
          <MainContainer>
            <HeadMain>
              <Head>{this.state.title}</Head>
              {
                !this.state.default_created &&
                <img
                  style={{ cursor: "pointer" }}
                  src={deleteButton}
                  alt="delete"
                  data-test-id="handleDeleteAddressList"
                  onClick={() =>
                    this.handleDeleteAddressListAll(this.state.addressListId)
                  }
                />
              }
            </HeadMain>
            <Divider />
            <SubTitle>{this.state.description}</SubTitle>
            <div style={{ marginTop: 36 }}>
              {this.state.listData?.map((item: any) => (
                <div key={item.id}>
                  <HeadMain>
                    <LocationMain>
                      <Img
                        src={item.attributes.location_image || location}
                        alt="location"
                      />
                      <Head>{item.attributes.address}</Head>
                    </LocationMain>
                    <img
                      style={{ cursor: "pointer" }}
                      src={action}
                      alt="action"
                      data-test-id="handleOpen"
                      onClick={(event) =>
                        this.handleOpen(event, item.id, item.address)
                      }
                    />
                  </HeadMain>
                  <CustomMenu
                    open={this.state.open}
                    onClose={this.handleClose}
                    anchorEl={this.state.anchorEl}
                    options={this.menuOptions(
                      this.state.deleteLocationId,
                      this.state.copyAddress
                    )}
                    position="right"
                  />
                  <Divider />
                </div>
              ))}
            </div>
          </MainContainer>
        </Main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Main = styled("div")({
  padding: "40px 36px",
});
const MainContainer = styled("div")({
  borderRadius: 24,
  boxShadow: `
      0px 8px 32px 0px #0000000F,
      0px 4px 8px 0px #00000008,
      0px 25px 50px 0px #00000017
    `,
  padding: "40px 36px",
});

const Back = styled("img")({
  marginBottom: 20,
  cursor: "pointer",
});

const HeadMain = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Head = styled("div")({
  ...CustomFontVariant.palette.font20400,
  lineHeight: "28px",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  marginBottom: "24px",
  marginTop: 12,
});

const SubTitle = styled("div")({
  ...CustomFontVariant.palette.font16400,
  lineHeight: "24px",
});

const Img = styled("img")({
  width: 56,
  height: 56,
  borderRadius: "50%",
});

const LocationMain = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 14,
});
// Customizable Area End
