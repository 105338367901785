import React from "react";

// Customizable Area Start
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  styled,
  CircularProgress,
} from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
// Customizable Area End

import NotificationsettingsController, {
  Props,
} from "./NotificationsettingsController";

export default class Notificationsettings extends NotificationsettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      pushNotifications,
      emailNotifications,
      inAppNotifications,
      loading,
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <Heading>Notification Settings</Heading>
        <Divider />
        {loading ? (
          <Box>
            <CircularProgress
              size={25}
              color={customTheme.palette.grey.primary}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={pushNotifications}
                  data-test-id="pushNotifications"
                  onChange={() => this.handleToggle("pushNotifications")}
                />
              }
              label={<StyledLabel>Push Notifications</StyledLabel>}
            />
            <Box
              height="1px"
              width="100%"
              bgcolor={customTheme.palette.grey.coolGrey}
              my={3}
            />
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={emailNotifications}
                  data-test-id="emailNotifications"
                  onChange={() => this.handleToggle("emailNotifications")}
                />
              }
              label={<StyledLabel>Email Notifications</StyledLabel>}
            />
            <Box
              height="1px"
              width="100%"
              bgcolor={customTheme.palette.grey.coolGrey}
              my={3}
            />
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={inAppNotifications}
                  data-test-id="inAppNotifications"
                  onChange={() => this.handleToggle("inAppNotifications")}
                />
              }
              label={<StyledLabel>In-App Notifications</StyledLabel>}
            />
            <Box
              height="1px"
              width="100%"
              bgcolor={customTheme.palette.grey.coolGrey}
              my={3}
            />
          </Box>
        )}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Heading = styled("div")({
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px",
});

const Divider = styled("div")({
  borderBottom: `1px solid ${customTheme.palette.grey.coolGrey}`,
  margin: "24px 0px",
});

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 28,
  margin: "0px 0px 0px 8px",
  padding: "1px",
  "& .MuiSwitch-switchBase": {
    top: 1,
    padding: "1px 0px 0px 4px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      padding: "1px 0px 0px 13px",
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: customTheme.palette.black.primary,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.type === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 40,
    backgroundColor: customTheme.palette.grey.coolGrey,
    opacity: 1,
    transition: theme.transitions.create("background-color", {
      duration: 500,
    }),
  },
}));

const StyledLabel = styled(Typography)({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  color: customTheme.palette.black.secondary,
  marginLeft: 16,
});
// Customizable Area End
