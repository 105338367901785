import React from "react";

// Customizable Area Start
import { copy, emailIcon } from "./assets";
import CustomModal from "../../..//components/src/CustomModal.web";
import CustomFontVariant from "../../..//components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import { Button, styled } from "@material-ui/core";
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <img
          src={this.props.shareIcon}
          alt="share"
          style={{ cursor: "pointer" }}
          onClick={this.handleShareModal}
          data-test-id="handleShareModal"
        />
        <CustomModal
          open={this.state.shareModalOpen}
          title="Share"
          showCloseIcon={true}
          onShowCloseIcon={this.handleShareModal}
          paperWidth={"unset"}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ButtonContained onClick={this.copyUrl} data-test-id="copyUrl">
              Copy link
              <img src={copy} alt="icon" style={{ marginLeft: 16 }} />
            </ButtonContained>
            <div style={webStyle.or}>or</div>
            <ButtonOutline
              onClick={this.ShareLinkViaEmail}
              data-test-id="ShareLinkViaEmail"
            >
              Share via Email
              <img src={emailIcon} alt="icon" style={{ marginLeft: 16 }} />
            </ButtonOutline>
          </div>
        </CustomModal>
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          severity={"success"}
          onClose={this.handleCloseToast}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="handleToastClose"
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  or: {
    margin: "40px 0px",
    color: customTheme.palette.grey.extraLight,
    ...CustomFontVariant.palette.font16400,
    lineHeight: '24px'
  },
};
const ButtonContained = styled(Button)({
  padding: "10px 60px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16400,
  textTransform: "none",
  border: "none",
  borderRadius: 16,
  marginTop: 40,
  lineHeight: '24px'
});

const ButtonOutline = styled(Button)({
  padding: "10px 40px",
  ...CustomFontVariant.palette.font16400,
  textTransform: "none",
  border: `1px solid${customTheme.palette.black.primary}`,
  color: customTheme.palette.black.primary,
  borderRadius: 16,
  lineHeight: '24px'
});
// Customizable Area End
