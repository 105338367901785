import React from "react";

// Customizable Area Start
import { Avatar, Button, styled } from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomModal from "../../../components/src/CustomModal.web";
import { Pagination } from "@material-ui/lab";
// Customizable Area End

import BlockedusersController, { Props } from "./BlockedusersController";

export default class Blockedusers extends BlockedusersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.BlockedUsers.map((item: any, index: any) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 16,
                alignItems: "center",
              }}
            >
              <PrifileMain2
                style={{ padding: "12px 0px" }}
                key={index}
                data-test-id="navigateToFriendProfile"
                onClick={() => {
                  this.handleNavigation(
                    "FriendsProfile",
                    item.attributes.account_id
                  );
                }}
              >
                <FriendProfileMain2>
                  <FriendProfileImg2 src={item.attributes.profile_image} />
                  <FriendProfileName2>
                    {item.attributes.name}
                  </FriendProfileName2>
                </FriendProfileMain2>
              </PrifileMain2>
              <div>
                <ButtonContained2
                  variant="outlined"
                  data-test-id="openUnBlockModal"
                  onClick={() =>
                    this.openUnBlockModal(item.id, item.attributes.name)
                  }
                >
                  Unblock
                </ButtonContained2>
              </div>
            </div>
            <Divider />
          </div>
        ))}
        <CustomModal
          open={this.state.openModal}
          cancelButtonText="Cancel"
          data-test-id="unBlockUser"
          doneButtonText="Unblock"
          onCancel={this.handleUnBlockCancel}
          onDone={() => this.unBlockUser(this.state.userId)}
          title="Unblock Account"
          subtitle={
            <>
              Are you sure you want to Unblock{" "}
              <span style={{ fontWeight: 700 }}>{this.state.userName}</span>?
            </>
          }
        ></CustomModal>
        {this.state.dataNotFound && (
          <DataNotFound>{this.state.dataNotFound}</DataNotFound>
        )}
        {this.state.totalCount > 1 && (
          <CustomPagination
            count={this.state.totalCount}
            variant="text"
            siblingCount={0}
            page={this.state.currentPage}
            onChange={this.handlePageChange}
            data-test-id="handlePageChange"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PrifileMain2 = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ButtonContained2 = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  border: "none",
});

const FriendProfileMain2 = styled("div")({
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  gap: 12,
});

const FriendProfileName2 = styled("div")({
  ...CustomFontVariant.palette.font20400,
  lineHeight: "28px",
});

const FriendProfileImg2 = styled(Avatar)({
  width: 56,
  height: 56,
  borderRadius: "50%",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const CustomPagination = styled(Pagination)({
  display: "flex",
  justifyContent: "center",
  marginTop: 24,
  "& .Mui-selected": {
    ...CustomFontVariant.palette.font16700,
  },
  "& .MuiPaginationItem-root": {
    color: customTheme.palette.grey.extraCoolGrey,
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "unset",
    color: customTheme.palette.black.primary,
  },
  "& .MuiSvgIcon-root": {
    color: customTheme.palette.grey.secondary,
  },
});

const DataNotFound = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font20700,
});

// Customizable Area End
