import React from "react";
import {
  Container, // Customizable Area Start
  Modal, // Customizable Area End
  Backdrop,
  Fade,
  adaptV4Theme,
} from "@mui/material";
// Customizable Area Start
import {
  createTheme,
  Theme
} from "@mui/material/styles";
import "@mui/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import CustomModal from "../../../components/src/CustomModal.web";
import { FormControl, MenuItem, Select, styled, Typography } from "@material-ui/core";
import { DownArrow } from "./assets";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import Toast from "../../../components/src/CustomSnackbar.web";
// Customizable Area End
import ContentFlagController, { Props } from "./ContentFlagController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ContentFlag extends ContentFlagController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomImageIndicator = () => (
    <img
      src={DownArrow}
      alt="Dropdown Indicator"
      style={{ width: 24, height: 24, marginRight: '10px' }} // Customize the size
    />
  );
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <img
          src={this.props.flagIcon}
          alt="flag"
          style={{ cursor: "pointer" }}
          onClick={this.handleFlagModal}
          data-test-id="handleFlageModal"
        />
        <CustomModal
          open={this.state.flagModalOpen}
          title="Report"
          paperWidth={"786px"}
          onShowCloseIcon={this.handleFlagModal}
          doneButtonText="Submit"
          cancelButtonText="Cancel"
          onCancel={this.handleFlagModal}
          onDone={this.createFlagData}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: '30px'
            }}
          >
            <Typography style={{...CustomFontVariant.palette.font24400, lineHeight: '32px'}}>Do you think this is in-appropriate content. Please let us know!</Typography>
            <Typography style={{...CustomFontVariant.palette.font14700, lineHeight: '22px', margin: '20px 0 10px'}}>Reason</Typography>
            <FormControl style={{ border: "1px solid #CBD5E1", borderRadius: "8px", marginBottom: '30px' }} variant="standard">
              <Select
                data-test-id="flag-category"
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={this.state.contentReason}
                onChange={(event: any) => {
                  this.setState({ contentReason: event.target.value })}}
                input={<this.BootstrapInput />}
                IconComponent={this.CustomImageIndicator}
                MenuProps={customMenuProps}
              >
                {
                  this.props.flagContent?.[0]?.['flag_catogory'].map((content: any) => (
                    <CustomMenuList
                      key={content.id}
                      value={content}
                    >
                      {content.reason}
                    </CustomMenuList>
                  ))
                }        </Select>
            </FormControl>
          </div>
        </CustomModal >
        <Toast
          open={this.state.showSuccessModal}
          message={this.state.successmessage}
          severity={"success"}
          onClose={this.handleFlagButtonClick}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="handleToastClose"
        />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const customMenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    anchorOrigin: {
      vertical: 'bottom' as 'bottom' | 'top' | 'center', // Must use 'bottom', 'top', or 'center'
      horizontal: 'left' as 'left' | 'right' | 'center',
    },
    transformOrigin: {
      vertical: 'top' as 'top' | 'bottom' | 'center',
      horizontal: 'left' as 'left' | 'right' | 'center',
    },
    style: {
      minWidth: '452px',
      maxHeight: '288px', // Set a max height for the dropdown list
      backgroundColor: "#FFFFFF",
      border: '1px solid #F5F5F5',
      borderRadius: '16px',
      marginTop: "50px",
      overflow: "visible",
      padding: '8px'
    },
  },
};

const CustomMenuList = styled(MenuItem) ({
  borderRadius: "8px",
  color: '#0F172A',
  background: "#FFFFFF",
  height: '40px',
  '&:hover': {
    backgroundColor: '#EAE7DC', // Hover background color
  },
  ...CustomFontVariant.palette.font16400,
  lineHeight: '24px', 
});

// Customizable Area End
