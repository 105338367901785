import React from "react";

// Customizable Area Start
import { Button, Typography, styled } from "@material-ui/core";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomModal from "../../../components/src/CustomModal.web";
import { eye, eyeOff, right, wrong } from "./assets";
import Toast from "../../../components/src/CustomSnackbar.web";
// Customizable Area End

import AccountSettingsWebController, {
  Props,
} from "./AccountSettingsWebController";

export default class AccountSettings extends AccountSettingsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { time } = this.state;
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainContainer>
        <Heading>Account Settings</Heading>
        <Divider />
        <Heading1>Change password</Heading1>
        <Label>Password</Label>
        <Label1>********</Label1>
        <ButtonComp
          data-test-id="handlePasswordCodeOpenModal"
          onClick={this.handlePasswordCodeOpenModal}
        >
          Change Password
        </ButtonComp>
        <CustomModal
          open={this.state.passwordCodeModal}
          data-test-id="handlePasswordCodeCancel"
          cancelButtonText="Cancel"
          doneButtonText="Confirm"
          onCancel={this.handlePasswordCodeCancel}
          onDone={this.handlePasswordCodeConfirm}
          title="Change Password"
          subtitle="We sent you an verification code on your email. Please open email to verify the change."
        >
          <InputDiv>
            <this.Input
              type="text"
              placeholder="Code"
              value={this.state.passwordCode}
              onChange={this.handlePasswordCode}
              isError={this.state.passwordCodeError}
              data-test-id="handlePasswordCode"
              maxLength={4}
            />
            {this.state.passwordCodeError && (
              <ErrorMsg>{this.state.passwordCodeError}</ErrorMsg>
            )}
            <TimeStemp>
              <Time>
                {minutes}:{seconds < 10 ? "0" + seconds : seconds} minutes
              </Time>
              <this.ReSesnd
                time={time}
                data-test-id="handleSendPasswordOtp"
                onClick={this.handleSendPasswordOtp}
              >
                Re-send Code
              </this.ReSesnd>
            </TimeStemp>
          </InputDiv>
        </CustomModal>
        <CustomModal
          open={this.state.passwordModal}
          cancelButtonText="Cancel"
          doneButtonText="Change"
          data-test-id="handlePasswordCancel"
          onCancel={this.handlePasswordCancel}
          onDone={this.handlePasswordConfirm}
          title="Change Password"
        >
          <EmailMain>
            <Label2>New Password</Label2>
            <InputWrapper>
              <this.Input
                type={this.state.newPasswordHide ? "text" : "password"}
                placeholder="Your New Password"
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange}
                isError={this.state.newPasswordError}
                data-test-id="handleNewPasswordChange"
              />
              <IconWrapper
                data-test-id="handleNewPasswordHide"
                onClick={this.handleNewPasswordHide}
              >
                {this.state.newPasswordHide ? (
                  <Eye src={eye} />
                ) : (
                  <Eye src={eyeOff} />
                )}
              </IconWrapper>
            </InputWrapper>
            {this.state.newPasswordError && (
              <ErrorMsg>{this.state.newPasswordError}</ErrorMsg>
            )}
          </EmailMain>
          <EmailMain>
            <Label2>Confirm New Password</Label2>
            <InputWrapper>
              <this.Input
                type={this.state.confirmPasswordHide ? "text" : "password"}
                placeholder="Confirm your new password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                isError={this.state.confirmPasswordError}
                data-test-id="handleConfirmPasswordChange"
              />
              <IconWrapper
                data-test-id="handleConfirmNewPasswordHide"
                onClick={this.handleConfirmNewPasswordHide}
              >
                {this.state.confirmPasswordHide ? (
                  <Eye src={eye} />
                ) : (
                  <Eye src={eyeOff} />
                )}
              </IconWrapper>
            </InputWrapper>
            {this.state.confirmPasswordError && (
              <ErrorMsg>{this.state.confirmPasswordError}</ErrorMsg>
            )}
          </EmailMain>
          <HeadingPassword>Your password must contain</HeadingPassword>
          <div>
            <PasswordMain>
              <PasswordCheckImg src={this.state.hasUpperCase ? right : wrong} />
              <PasswordCheck>At least one capital letter</PasswordCheck>
            </PasswordMain>
            <PasswordMain>
              <PasswordCheckImg src={this.state.hasLowerCase ? right : wrong} />
              <PasswordCheck>At least one lowercase letter</PasswordCheck>
            </PasswordMain>
            <PasswordMain>
              <PasswordCheckImg src={this.state.hasNumber ? right : wrong} />
              <PasswordCheck>At least one number</PasswordCheck>
            </PasswordMain>
            <PasswordMain>
              <PasswordCheckImg src={this.state.isMinLength ? right : wrong} />
              <PasswordCheck>
                Minimum character length is 8 characters
              </PasswordCheck>
            </PasswordMain>
          </div>
        </CustomModal>
        <Toast
          open={this.state.toast}
          message={this.state.OkPasswordMsg}
          severity={"success"}
          duration={3000}
          position={{ vertical: "bottom", horizontal: "center" }}
          data-test-id="handleToastClose"
          onClose={this.handleToastClose}
        />
        <Divider />
        <Heading1>Change Email</Heading1>
        <Label>Email</Label>
        <Label1>********@gmail.com</Label1>
        <ButtonComp
          data-test-id="handleEmailOpenModal"
          onClick={this.handleEmailOpenModal}
        >
          Change Email
        </ButtonComp>
        <CustomModal
          open={this.state.emailModal}
          cancelButtonText="Cancel"
          doneButtonText="Confirm"
          onCancel={this.handleEmailCancel}
          onDone={this.handleEmailConfirm}
          data-test-id="handleEmailConfirm"
          title="Change Email"
          subtitle="Enter new email"
        >
          <InputDiv>
            <this.Input
              type="email"
              placeholder="Email"
              value={this.state.newEmail}
              onChange={this.handleEmailChange}
              isError={this.state.emailError}
              data-test-id="handleEmailChange"
            />
            {this.state.emailError && (
              <ErrorMsg>{this.state.emailError}</ErrorMsg>
            )}
          </InputDiv>
        </CustomModal>
        <CustomModal
          open={this.state.emailCodeModal}
          data-test-id="handleEmailCodeCancel"
          cancelButtonText="Cancel"
          doneButtonText="Confirm"
          onCancel={this.handleEmailCodeCancel}
          onDone={this.handleEmailCodeConfirm}
          title="Change Email"
          subtitle="We sent you an verification code on your email. Please open email to verify the change."
        >
          <InputDiv>
            <this.Input
              type="text"
              placeholder="Code"
              value={this.state.emailCode}
              onChange={this.handleEmailCode}
              isError={this.state.emailCodeError}
              data-test-id="handleEmailCode"
              maxLength={4}
            />
            {this.state.emailCodeError && (
              <ErrorMsg>{this.state.emailCodeError}</ErrorMsg>
            )}
            <TimeStemp>
              {minutes}:{seconds < 10 ? "0" + seconds : seconds} minutes
              <this.ReSesnd time={time} onClick={this.handleSendEmailOtp}>
                Re-send Code
              </this.ReSesnd>
            </TimeStemp>
          </InputDiv>
        </CustomModal>
        <CustomModal
          open={this.state.OkEmailModal}
          doneButtonText="Ok"
          onDone={this.handleOkEmailCodeConfirm}
          data-test-id="handleOkEmailCodeConfirm"
          title="Change Email"
          subtitle={this.state.OkEmailMsg}
        ></CustomModal>
        <Divider />
        <Heading1>Delete account</Heading1>
        <SubHeading>
          All of the information stored in system will be permanently deleted.
        </SubHeading>
        <ButtonDelete
          data-test-id="handleDeleteOpenModal"
          onClick={this.handleDeleteOpenModal}
        >
          Delete Account
        </ButtonDelete>
        <CustomModal
          open={this.state.deleteModal}
          cancelButtonText="Keep Account"
          doneButtonText=" Delete account"
          onCancel={this.handleDeleteCancel}
          onDone={this.handleDeleteConfirm}
          title="Delete Account"
          subtitle="Are you sure you want to delete your account?"
        ></CustomModal>
        <CustomModal
          open={this.state.deleteModalSuccessfully}
          doneButtonText="Ok"
          onDone={this.handleDeleteSuccessfully}
          title="Delete Account"
          subtitle="Email account was successfully deleted !"
        ></CustomModal>
        <Divider />
        <ButtonLogout variant="outlined" onClick={this.handleLogOutOpenModal}>
          Log Out
        </ButtonLogout>
        <CustomModal
          open={this.state.logOutModal}
          cancelButtonText="Cancel"
          data-test-id="handleLogOutConfirm"
          doneButtonText="Logout"
          onCancel={this.handleLogOutCancel}
          onDone={this.handleLogOutConfirm}
          title="Logout"
          subtitle="Are you sure you want to logout of your account ?"
        ></CustomModal>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled("div")({});

const Heading = styled("div")({
  ...CustomFontVariant.palette.font24700,
  lineHeight: "32px",
});

const Divider = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
  margin: "30px 0px",
});

const Heading1 = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
});

const Label = styled("div")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
  marginTop: 30,
});

const Label1 = styled("div")({
  ...CustomFontVariant.palette.font14400,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
  margin: "4px 0px 16px 0px",
});

const ButtonComp = styled(Button)({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  textTransform: "none",
  backgroundColor: customTheme.palette.grey.primary,
  minWidth: 143,
});

const ButtonDelete = styled(Button)({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  textTransform: "none",
  backgroundColor: customTheme.palette.red.secondary,
  minWidth: 143,
  marginTop: 30,
});

const SubHeading = styled("div")({
  marginTop: 8,
  ...CustomFontVariant.palette.font14400,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
});

const ButtonLogout = styled(Button)({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  textTransform: "none",
  color: customTheme.palette.red.primary,
  minWidth: 256,
  minHeight: 42,
  border: `1px solid${customTheme.palette.red.secondary}`,
  marginBottom: 100,
});

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const InputDiv = styled("div")({
  margin: "40px 0px",
});

const TimeStemp = styled("div")({
  display: "flex",
  gap: 24,
  marginTop: 17,
  justifyContent: "end",
  alignItems: "center",
});

const Time = styled("div")({
  ...CustomFontVariant.palette.font14400,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
});

const EmailMain = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: 26,
});

const Label2 = styled("label")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
  marginBottom: 5,
  marginTop: 16,
});

const HeadingPassword = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  marginTop: 40,
  color: customTheme.palette.grey.bold,
});

const PasswordCheck = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
});

const PasswordCheckImg = styled("img")({
  height: 12,
  width: 12,
});

const PasswordMain = styled("div")({
  display: "flex",
  gap: 10,
  alignItems: "center",
  marginTop: 8,
});

const IconWrapper = styled("div")({
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

const Eye = styled("img")({
  color: customTheme.palette.grey.extraLight,
  width: 20,
});

// Customizable Area End
