import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
  Link,
} from "@mui/material";

// Customizable Area Start
import { Modal, styled } from "@material-ui/core";
import {
  addBrowse,
  close,
  closeFile,
  next,
  previous,
  uploadBrowse,
  uploadButton,
} from "./assets";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Main>
          <ContainerMain>
            {this.state.filesData?.map((item: any) => (
              item.attributes?.images.map((image: any, index: any) => (
                <StyledImage
                key={index}
                src={image?.file_url}
                alt={`photo-${index}`}
                onClick={() => this.handleOpenImageModal(index)}
                data-test-id="handleOpenImageModal"
                isApproved={image?.is_approved}
              />))
            ))}
          </ContainerMain>
          <UploadButton
            variant="contained"
            data-test-id="handleBulkUploadModal"
            onClick={this.handleBulkUploadModal}
          >
            <img src={uploadButton} alt="upload" />
          </UploadButton>
          <StyledModal
            open={this.state.openImageModal}
            onClose={this.handleCloseImageModal}
            data-test-id="handleCloseImageModal"
            BackdropProps={{
              onClick: (e) => e.stopPropagation(),
            }}
          >
            <ModalContent>
              <CloseButton
                src={close}
                alt="close"
                onClick={this.handleCloseImageModal}
              />
              {this.state.selectedImageIndex > 0 && (
                <NavButtonLeft
                  src={previous}
                  alt="previous"
                  data-test-id="goToPreviousImage"
                  onClick={this.goToPreviousImage}
                />
              )}
              {this.state.selectedImageIndex <
                this.state.filesData?.[0]?.attributes?.images.length - 1 && (
                <NavButtonRight
                  src={next}
                  alt="next"
                  data-test-id="goToNextImage"
                  onClick={this.goToNextImage}
                />
              )}
              <ImgView
                src={
                  this.state.filesData?.[0]?.attributes?.images[this.state.selectedImageIndex]?.file_url
                }
                alt={`Image ${this.state.selectedImageIndex}`}
                isApproved={
                  this.state.filesData?.[0]?.attributes?.images[this.state.selectedImageIndex]?.is_approved
                }
              />
            </ModalContent>
          </StyledModal>
        </Main>
        {this.state.BuldUploadModal && (
          <ParentModal1>
            <ChildModal1>
              <Heading>Upload</Heading>
              <DropZone
                data-test-id="dropZone"
                onDrop={this.handleDrop}
                onDragOver={this.handleDragOver}
              >
                <DropZoneChid>
                  <img src={uploadBrowse} alt="uploadIcon" />
                  <TitleParent>
                    <Title>Upload your files here</Title>
                    <Subtitle>Drag and drop the files here</Subtitle>
                  </TitleParent>
                  <BrowseButton htmlFor="fileInput">
                    <img src={addBrowse} alt="addIcon" />
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      data-test-id="handleFileSelect"
                      onChange={this.handleFileSelect}
                      id="fileInput"
                    />
                    <ButtonTitle>Browse</ButtonTitle>
                  </BrowseButton>
                </DropZoneChid>
              </DropZone>
              {this.state.error && (
                <ErrorMsg color="error">{this.state.error}</ErrorMsg>
              )}
              <FilesMain>
                {this.state.files.map((file, index) => (
                  <Files key={index}>
                    <FileChild>
                      <Child>
                        <ImgThumb
                          src={URL.createObjectURL(file)}
                          alt="file-thumbnail"
                        />
                        <div>
                          <FileName>{file.name}</FileName>
                          <FileSize>
                            {file.size / 1024 < 1024
                              ? `${(file.size / 1024).toFixed(2)} KB`
                              : `${(file.size / 1024 / 1024).toFixed(2)} MB`}
                          </FileSize>
                        </div>
                      </Child>
                      <img
                        src={closeFile}
                        alt="closeIcon"
                        style={{ cursor: "pointer" }}
                        data-test-id="handleRemoveFile"
                        onClick={() => this.handleRemoveFile(index)}
                      />
                    </FileChild>
                  </Files>
                ))}
              </FilesMain>
              <div style={webStyle.buttonContainer}>
                <ButtonCancel
                  variant="outlined"
                  onClick={this.handleBulkUploadModal}
                  style={{ marginRight: "1rem" }}
                >
                  Cancel
                </ButtonCancel>
                <ButtonDone
                  variant="contained"
                  data-test-id="uploadGalleryImage"
                  onClick={this.uploadGalleryImage}
                >
                  Send
                </ButtonDone>
              </div>
            </ChildModal1>
          </ParentModal1>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
  },
};
const Main = styled("div")({
  marginTop: 34,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ContainerMain = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
  columnGap: "16px",
  rowGap: "30px",
  width: "100%",
  marginTop: 34,
  overflow: "auto",
  maxHeight: 430,
});

const StyledImage = styled("img")(({ isApproved }: any) => ({
  width: "100%",
  height: "auto",
  objectFit: "cover",
  aspectRatio: "4 / 3",
  cursor: "pointer",
  filter: isApproved ? "none" : "blur(10px)",
}));

const UploadButton = styled(Button)({
  marginTop: "60px !important",
  padding: "10px 40px !important",
  backgroundColor: `${customTheme.palette.grey.primary} !important`,
  color: `${customTheme.palette.black.primary} !important`,
  ...CustomFontVariant.palette.font16700,
  border: "none",
});

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled("div")({
  boxShadow: "0px 8px 32px 0px #0000000F",
  outline: "none",
  maxWidth: "90vw",
  maxHeight: "80vh",
  width: "auto",
  position: "relative",
});

const CloseButton = styled("img")({
  position: "absolute",
  top: "-1rem",
  right: "-1rem",
  cursor: "pointer",
  zIndex: 1000,
  filter: "none",
  width:36,
  height:36,
});

const NavButtonLeft = styled("img")({
  position: "absolute",
  top: "50%",
  left: "-8rem",
  transform: "translateY(-50%)",
  cursor: "pointer",
});

const NavButtonRight = styled("img")({
  position: "absolute",
  top: "50%",
  right: "-8rem",
  transform: "translateY(-50%)",
  cursor: "pointer",
});

const ImgView = styled("img")(({ isApproved }: any) => ({
  width: "40vw",
  height: "75vh",
  objectFit: "cover",
  filter: isApproved ? "none" : "blur(10px)",
}));

const Heading = styled("div")({
  ...CustomFontVariant.palette.font20700,
  marginBottom: 24,
  display: "flex",
  justifyContent: "center",
  lineHeight: "28px",
});

const DropZone = styled("div")({
  width: "100%",
  height: 218,
  border: `3px dashed ${customTheme.palette.grey.light}`,
  borderRadius: 16,
  boxShadow: "0px 4px 8px 0px #00000008",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const DropZoneChid = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
});

const TitleParent = styled("div")({
  textAlign: "center",
});

const Title = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  color: customTheme.palette.grey.bold,
});

const Subtitle = styled("div")({
  ...CustomFontVariant.palette.font14400,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
});

const BrowseButton = styled("label")({
  display: "flex",
  gap: 8,
  alignItems: "center",
  cursor: "pointer",
});

const ButtonTitle = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  color: customTheme.palette.blue.primary,
  cursor: "pointer",
});

const FilesMain = styled("div")({
  marginTop: 40,
  maxHeight: 210,
  overflow: "auto",
});

const Files = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "16px 12px 16px 16px",
  boxShadow: `
    0px 8px 32px 0px #0000000F, 
    0px 4px 8px 0px #00000008  
  `,
  marginBottom: 16,
});

const FileChild = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FileName = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.black.secondary,
});

const FileSize = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.extraCoolGrey,
});

const ImgThumb = styled("img")({
  width: "56px",
  height: "56px",
  objectFit: "cover",
  borderRadius: 8,
});

const Child = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 16,
});

const ParentModal1 = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModal1 = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px 24px",
  borderRadius: "20px",
  maxHeight: 950,
  margin: "150px auto",
  position: "relative",
});

const ButtonDone = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  backgroundColor: `${customTheme.palette.grey.primary}!important`,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
  borderColor: `${customTheme.palette.black.primary}!important`,
});

const ErrorMsg = styled(Typography)({
  fontSize: "14px !important",
  fontWeight: 400,
  fontFamily: "Open Sans !important",
  color: `${customTheme.palette.red.primary}!important`,
  marginTop: "8px !important",
});

// Customizable Area End
