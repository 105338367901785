import React from "react";

// Customizable Area Start
import { Grid, styled, Typography } from "@material-ui/core";
import customFontVariant from "../../../components/src/CustomFontVariant.web";
import Header from "../../../blocks/landingpage/src/Header.web";
import customTheme from "../../../components/src/CustomTheme.web";
import AccountSettings from "./AccountSettings.web";
import Notificationsettings from "../../../blocks/notificationsettings/src/Notificationsettings.web";
import PrivacySettings from "../../privacysettings/src/PrivacySettings.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import Subscriptionbilling2 from "../../subscriptionbilling2/src/Subscriptionbilling2.web";
import Contactus from "../../contactus/src/Contactus.web";

const navigation:any = "";
// Customizable Area End

import AccountSettingsWebController, {
  Props,
} from "./AccountSettingsWebController";

export default class Settings extends AccountSettingsWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} />
        {!this.state.isSubscribe && <Main>
          <MainGrid container>
            <GridFirst item sm={4} md={4}>
              <Title>Settings</Title>
              {this.menu.map((item: any) => (
                <MenuItem key={item.id}>
                  <this.Menu
                    onClick={() => this.handleChange(item.id)}
                    value={this.state.value === item.id}
                    data-test-id="handleChange"
                  >
                    {item.name}
                  </this.Menu>
                </MenuItem>
              ))}
            </GridFirst>
            <GridSecond item sm={8} md={8}>
              <div>
                {this.state.value === 1 && (
                  <AccountSettings navigation={undefined} id={""} />
                )}
                {this.state.value === 2 && (
                  <PrivacySettings navigation={navigation} id={""} privacyKey={this.state.privacyKey} updatePrivacyKey={(value: string) => this.setState({privacyKey: value})} />
                )}
                {this.state.value === 3 && (
                  <Notificationsettings navigation={undefined} id={""}/>
                )}
                {this.state.value === 4 && (
                  <Subscriptionbilling2 navigation={undefined} id={""} isSubscribe={this.state.isSubscribe} handleSubscribe={this.handleSubscribe} />
                )}
                {this.state.value === 5 && (
                  <Contactus navigation={undefined} id={""} token={this.state.token}/>
                )}
                {this.state.value === 6 && (
                  <TermsConditions navigation={undefined} id={""} privacyText={this.state.termsCondition} />
                )}
              </div>
            </GridSecond>
          </MainGrid>
        </Main>}
        {
          this.state.isSubscribe && <Subscriptionbilling2 navigation={this.props.navigation} id={""} isSubscribe={this.state.isSubscribe} handleSubscribe={this.handleSubscribe} />
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainGrid = styled(Grid)({
  borderRight: `1px solid${customTheme.palette.grey.coolGrey}`,
  borderLeft: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const Main = styled("div")({
  padding: "0px 40px",
});
const Title = styled(Typography)({
  padding: "16px 0px 30px 24px",
  ...customFontVariant.palette.font30700,
  lineHeight: "40px",
  color: customTheme.palette.black.primary,
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const MenuItem = styled("div")({
  marginBottom: 8,
  marginTop: 24,
  padding: "0px 8px",
});

const GridFirst = styled(Grid)({
  borderRight: `1px solid${customTheme.palette.grey.coolGrey}`,
  maxWidth: 362,
});

const GridSecond = styled(Grid)({
  padding: "24px",
});

// Customizable Area End
