import React from "react";

// Customizable Area Start
import CustomModal from "../../../components/src/CustomModal.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import { Avatar, Button, styled, Box, Typography } from "@material-ui/core";
import { chat, close, deleteIcon, flag } from "./assets";
import Likeapost2 from "../../likeapost2/src/Likeapost2.web";
import Toast from "../../../components/src/CustomSnackbar.web";
import ContentFlag from "../../../blocks/contentflag/src/ContentFlag.web";
// Customizable Area End

import CommentsController, { Props, configJSON } from "./CommentsController";

export default class Comments extends CommentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderComments = (
    comments: any[],
    paddingLeft: number = 0,
    isNested: boolean = false,
    parentCommentId: number = 0
  ) => {
    return comments.map((item, index) => {
      const {
        profile_image,
        name,
        comment,
        like,
        current_user_comment,
        current_user_like,
        replies,
        post_id,
      } = item.attributes;
      const { id } = item;
      return (
        <div key={index}>
          <HomeListMain paddingLeft={isNested ? paddingLeft : 0}>
            <HomeListImg src={profile_image} />
            <HomeListInner>
              <HomeListName>{name}</HomeListName>
              <HomeDistription>{comment}</HomeDistription>
              <ActionButton>
                <Likeapost2
                  navigation={this.props.navigation}
                  id={""}
                  commentId={id}
                  likeCount={like}
                  currentUserLike={current_user_like}
                  getAllDataCallBack={() => this.handleGetComment(post_id)}
                  data-test-id="handleGetComment"
                />
                <ActionCount>
                  <Label
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.handleReply(id);
                    }}
                    data-test-id="handleReply"
                  >
                    Reply
                  </Label>
                </ActionCount>
                {current_user_comment && (
                  <>
                    <Img
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={() =>
                        this.handleCommentDeleteDoneModal(id, isNested)
                      }
                      data-test-id="handleCommentDeleteDoneModal"
                    />
                    <CustomModal
                      open={this.state.deleteCommentModalOpen}
                      data-test-id="handleDeleteComment"
                      cancelButtonText="Cancel"
                      doneButtonText="Delete"
                      onCancel={this.handleCommentDeleteDoneModal}
                      onDone={() => this.handleDeleteComment()}
                      title="Delete"
                      subtitle="You are about to delete your comment. Are you sure you want to delete this comment?"
                    />
                  </>
                )}
                {!current_user_comment && <ContentFlag
                  flagIcon={flag}
                  flagContent={this.props.flagCategory}
                  contentId={id}
                  parentCommentId={parentCommentId}
                  flagFor="Comment"
                  isReply={isNested}
                />}
              </ActionButton>
            </HomeListInner>
          </HomeListMain>
          <div style={webStyle.divider} />
          {replies && this.renderComments(replies, 55, true, id)}
        </div>
      );
    });
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ActionCount
          data-test-id="handleOpenModal"
          onClick={this.handleOpenModal}
        >
          <Count>{this.props.CommentCount}</Count>
          <Img src={chat} alt="actionIcon" />
        </ActionCount>
        {this.state.openModal && (
          <ParentModal>
            <ChildModal>
              <CloseIcon onClick={this.handleOpenModal}>
                <img src={close} alt="close" />
              </CloseIcon>
              <CommendHeading>{configJSON.Comments}</CommendHeading>
              <div style={{ maxHeight: 900, overflowY: "auto" }}>
                {this.renderComments(this.state.allComments)}
              </div>
              <div style={webStyle.comment}>
                <HomeListImg src={this.state.profileUrl} />
                <Input
                  type="text"
                  placeholder={
                    this.state.isReply ? "Your Reply" : "Your Comment"
                  }
                  value={this.state.newComment}
                  onChange={this.handleCommentChange}
                  data-test-id="handleNameChange"
                  isError={!!this.state.commentError}
                />
                {this.state.isReply ? (
                  <>
                    <ButtonContained
                      variant="contained"
                      data-test-id="handleCommentPost"
                      onClick={() =>
                        this.handleCommentPost(
                          this.props.postId || this.props.eventId,
                          this.state.commentId
                        )
                      }
                    >
                      Reply
                    </ButtonContained>
                    <ButtonCancel
                      variant="outlined"
                      onClick={this.handleReplyCancel}
                      data-test-id="handleReplyCancel"
                    >
                      Cancel
                    </ButtonCancel>
                  </>
                ) : (
                  <ButtonContained
                    variant="contained"
                    data-test-id="handleCommentPostReply"
                    onClick={() =>
                      this.handleCommentPost(
                        this.props.postId || this.props.eventId
                      )
                    }
                  >
                    Comment
                  </ButtonContained>
                )}
              </div>
              {this.state.commentError && (
                <ErrorMsg>{this.state.commentError}</ErrorMsg>
              )}
            </ChildModal>
          </ParentModal>
        )}
        <Toast
          open={this.state.commentToastOpen}
          message={this.state.commentToastMessage}
          severity={"success"}
          onClose={this.handleCommentCloseToast}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="handleToastClose"
        />
        <Toast
          open={this.state.isCommentToastError}
          message={this.state.commentToastMessage}
          severity={"error"}
          onClose={this.handleCommentCloseToast}
          duration={3000}
          position={{ vertical: "top", horizontal: "center" }}
          data-test-id="handleToastClose"
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  divider: {
    borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
    margin: "10px 0px 24px 0px",
  },
  comment: {
    display: "flex",
    gap: 24,
    alignItems: "center",
  },
};

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.black.primary,
  lineHeight: "22px"
});

const Input = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  minHeight: 52,
  lineHeight: "24px"
});

const HomeListMain = styled("div")(({ paddingLeft }: any) => ({
  display: "flex",
  paddingLeft,
}));

const HomeListName = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px"
});

const HomeDistription = styled("div")({
  ...CustomFontVariant.palette.font14400,
  color: customTheme.palette.black.secondary,
  lineHeight: "22px"
});

const HomeListImg = styled(Avatar)({
  width: 44,
  height: 44,
});

const HomeListInner = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  paddingLeft: 10,
});

const ActionButton = styled("div")({
  display: "flex",
  gap: 16,
});

const Img = styled("img")({
  cursor: "pointer",
});

const Count = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: '24px',
});

const ActionCount = styled("div")({
  display: "flex",
  gap: 4,
  cursor: "pointer",
  alignItems: "end",
});

const CommendHeading = styled("div")({
  ...CustomFontVariant.palette.font20700,
  marginBottom: 24,
  display: "flex",
  justifyContent: "center",
  lineHeight: "28px"
});

const ParentModal = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModal = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px 24px",
  borderRadius: "20px",
  maxHeight: 1050,
  margin: "50px auto",
  position: "relative",
});

const CloseIcon = styled("div")({
  position: "absolute",
  top: -18,
  right: -20,
  cursor: "pointer",
});

const ErrorMsg = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
  marginLeft: 72,
});

const ButtonCancel = styled(Button)({
  padding: "10px 40px",
  minHeight: 52,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
});

// Customizable Area End
