import React from "react";

// Customizable Area Start
import {
  Modal,
  Box,
  Button,
  Typography,
  Input,
  InputBase
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
const uploadFile = require("../assets/uploadFile.svg")
const emojiIcon = require("../assets/emojiIcon.svg")
const sendMessage = require("../assets/sendMessage.svg")
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmojiPicker from 'emoji-picker-react';
import CustomMenu from "../../../components/src/CustomMenuItem.web";
import { backButton, checkMark, searchIcon, uploadIcon, moreVert } from "./assets";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomModal from "../../../components/src/CustomModal.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress, Grid, styled } from "@material-ui/core";
import Toast from "../../../components/src/CustomSnackbar.web"
import { GroupFormData, ISearchFriendsList } from "./ChatController";
import { FriendProfileImg, SearchFriendBox, SearchIcon, SearchInput, SearchItem, SearchItemBox, SearchList } from "./Chat.web";
const PlusIcon = require("../assets/plus_icon.png")

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ViewChatController, {
  configJSON,
  IMessage,
  Props,
} from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMessages = () => {
    const { chatData } = this.state;
    const senderName = this.props.senderName || "";
    const messages = chatData ?? [];

    return (
      <Box sx={{ marginBottom: '40px' }} data-test-id="messageBoxContainer">
        {messages.map((item: IMessage, index: number) => {
          const isPrivateChat = this.props.chatType === "private";
          const isSender = item.sender.id === this.props.accountId;

          return (
            <Box
              key={`message-${index}`}
              sx={{
                ...webStyles.messageBoxContainer,
                flexDirection: isPrivateChat || !isSender ? 'row' : 'row-reverse',
              }}
            >
              {(isPrivateChat || (!isPrivateChat && !isSender)) && (
                <Box sx={{ marginRight: '16px' }}>
                  {this.getAvatar(item)}
                </Box>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{
                  padding: isPrivateChat ? '0' : '8px 12px',
                  borderRadius: this.getBorderRadiusForGroupMessage(item, isSender),
                  backgroundColor: this.getBackgroundColorForGroupMessage(isPrivateChat, isSender),
                }}>
                  {this.getSenderName(isPrivateChat, isSender, senderName, item) && (
                    <Typography style={{ fontSize: '14px', fontWeight: '700', lineHeight: "22px", fontFamily: "Open Sans", marginBottom: '4px' }}>
                      {this.getSenderName(isPrivateChat, isSender, senderName, item)}
                    </Typography>
                  )}

                  {item.text && (
                    <Typography style={{fontSize: '14px', fontWeight: '400', lineHeight: "22px", fontFamily: "Open Sans",}}>
                      {item.text}
                    </Typography>
                  )}
                </Box>

                {this.displayAttachedFiles(item)}

                {this.props.chatType == 'group' && (
                  <Typography style={{ fontSize: '12px', color: '#888', marginTop: '4px', textAlign: isSender ? 'right' : 'left'}} data-test-id="groupMessageTime">
                    {new Date(item.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true  })}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  getBorderRadiusForGroupMessage = (item: IMessage, isSender: boolean) => {
    if (item.attachmentUrls && item.attachmentUrls.length > 0) {
      return isSender ? '16px 0 0 0' : '0 16px 0 0';
    }
    return isSender ? '16px 0 16px 16px' : '0 16px 16px 16px';
  };

  getBackgroundColorForGroupMessage = (isPrivateChat: boolean, isSender: boolean) => {
    if (isPrivateChat) {
      return 'transparent';
    }
    return isSender ? '#EAE7DC' : 'rgba(226, 232, 240, 0.4)';
  };

  getSenderName = (isPrivateChat: boolean, isSender: boolean, senderName: string, item: IMessage) => {
    if (!isPrivateChat && isSender) {
      return null;
    }
    return isSender ? senderName : item.sender.name
  }

  displayAttachedFiles = (item: IMessage) => {
    const groupChat = this.props.chatType == 'group';

    return (
      <>
        {item.attachmentUrls && item.attachmentUrls?.length > 0 && (
          <Box sx={{
            borderRadius: groupChat ? '0 0 16px 16px' : '16px',
            overflow: 'hidden',
            marginTop: groupChat ? '' : '16px'
          }}>
            {item.attachmentUrls.map((url, index) => (
              url.endsWith(".jpg") || url.endsWith(".png") || url.endsWith(".jpeg") || url.endsWith(".svg") ? (
                <img
                  key={index}
                  src={url}
                  style={ groupChat ? webStyles.previewImageGroup : webStyles.previewImage}
                  alt={`attachment-${index}`}
                />
              ) : (
                <div key={index}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {url.split("/").pop()}
                  </a>
                </div>
              )
            ))}
          </Box>
        )}
      </>
    )
  }

  getAvatar = (item: IMessage) => {
    const { accountId, receiverName, receiverPhoto, senderName, chatType } = this.props;
    const { profileImage } = this.state;

    const renderImage = (src: string, alt: string) => (
      <img src={src} alt={alt} style={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover' }} />
    );

    const renderAvatarCircle = (name: string) => (
      <Box sx={webStyles.avatarCircle}>
        {name ? name.charAt(0).toUpperCase() : 'N/A'}
      </Box>
    );

    if (chatType === 'private') {
      const isSender = item.sender.id == accountId;
      const imageSrc = isSender ? profileImage : receiverPhoto;
      const name = isSender ? senderName : receiverName;

      return imageSrc ? renderImage(imageSrc, name || "name") : renderAvatarCircle(name || "N/A");
    }

    return item.sender.photo
      ? renderImage(item.sender.photo, item.sender.name || "")
      : renderAvatarCircle(item.sender.name);
  };

  renderDropDownMenu = () => {
    return (
      <div>
        <MoreHorizIcon
          style={{ cursor: "pointer" }}
          onClick={this.handleClickOptions}
          data-test-id="handleClickOptions"
        />
        <CustomMenu
          open={this.state.openChatOptions}
          onClose={this.handleCloseOptions}
          anchorEl={this.state.anchorElCoverOptions}
          options={this.props.chatType == 'private' ? this.menuOptionsOptions : this.menuOptionsForGroups}
          position="right"
          data-test-id="handleCloseOptions"
          correctionId={this.props.correctionId}
        />
      </div>
    );
  };

  renderViewContact = () => {
    const { receiverName, receiverPhoto, receiverBio, receiverEmail, mutualGroups } = this.props;
    const { showDataCount, groupAttachments } = this.state;
    const isAllDataShown = showDataCount >= groupAttachments.length;
    return (
      <Box sx={{ borderRight: `1px solid ${customTheme.palette.grey.light}`, width: "100%" }}>
        <Box sx={webStyles.headerViewContact}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
          >
            <img
              src={backButton}
              alt="backIcon"
              onClick={this.props.viewContactCallback}
              data-test-id="handleBackArrow"
            />
            <div style={{ ...CustomFontVariant.palette.font20700, fontFamily: "Inter", lineHeight: "28px" }}>
              Contact
            </div>
          </div>
        </Box>
        <Grid container spacing={6} style={{ paddingInline: 40 }}>
          <Grid item md={6}>
          {receiverPhoto ? (
               <ProfileImage src={receiverPhoto} alt="profileImg" />
            ) : (
              <Box sx={webStyles.avatarSquare} data-test-id="avatarSquare">
                {receiverName ? receiverName.charAt(0).toUpperCase() : ""}
              </Box>
            )}
           
            <Title>{receiverName}</Title>
            <Description>{receiverEmail}</Description>
            <Description>
              {receiverBio}
            </Description>
            <div
              style={{
                display: "flex",
                gap: 16,
                alignItems: "center",
                marginTop: 40,
              }}
            >
              <ButtonContained data-test-id="unfriendBtn" disabled={this.props.correctionId === null || this.props.correctionId === 0} onClick={this.handleUnfriendModal}>
                Unfriend
              </ButtonContained>
              <ButtonContainedRed data-test-id="blockBtn" onClick={this.handleBlockAccountModal}>
                Block
              </ButtonContainedRed>
              <ButtonContainedRed data-test-id="reportBtn" onClick={this.handleReportModal}>
                Report
              </ButtonContainedRed>
            </div>
          </Grid>
          <Grid item md={6}>
            <Title1>Media:</Title1>
            <ContainerMain>
               {this.state.groupAttachments
                ?.slice(0, this.state.showDataCount)
                .map((url: any, index) => {
                  return <StyledImage
                    key={index}
                    src={url}
                    alt={`photo-${index}`}
                    data-test-id="handleOpenImageModal"
                  />
                })}
            </ContainerMain>
            {
              this.state.groupAttachments.length > 10 && <ShowMoreButton
              disabled={isAllDataShown}
              onClick={this.handleShowMore}
              data-test-id="handleShowMore"
            >
              show more
            </ShowMoreButton>
            }
            <Title2>Mutual Groups:</Title2>
            <div style={{ maxHeight: 268, overflowY: "auto" }}>
              {
                mutualGroups?.map((group) => (
                  <Parent key={group.id}>
                    <Img src={group.attributes.group_image || ""} alt="profile" />
                    <div>
                      <div style={{ ...CustomFontVariant.palette.font14700, fontFamily: "Inter", lineHeight: "22px" }}>
                        {group.attributes.name}
                      </div>
                      <EllipseMsg>{group.attributes.description}</EllipseMsg>
                    </div>
                  </Parent>
                ))
              }
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  };

  renderSearchList = (friend: ISearchFriendsList) => {
    const { photo, group_image, name } = friend.attributes;
  
    let imageSrc;

    if (friend.type === "connection_chat") {
      imageSrc = photo;
    } else if (friend.type === "group_chat_list") {
      imageSrc = group_image;
    }

    return imageSrc ? (
      <FriendProfileImg 
        src={imageSrc} 
        alt={name || ""} 
        data-test-id={friend.type === "connection_chat" ? "friendPhoto" : "groupImage"} 
      />
    ) : (
      <Box sx={webStyles.avatarCircle} data-test-id="circleWithLetter">
        {name?.charAt(0).toUpperCase()}
      </Box>
    );
  };

  renderAddMemberModal = () => {
    const filteredRecords = this.props.searchFriendsListForGroup.filter(record2 =>
      this.props.selectedGroupMember.every(record1 => record1.attributes.account_id !== record2.attributes.receipient_id)
    );

    return (
      <>
        {this.state.addMemberModalOpen && (
        <CreateChatModal>
          <CreateChatModalContainer>
            <CreateChatTitle>
              Add member
            </CreateChatTitle>
            <SearchFriendBox>
              <SearchIcon src={searchIcon} alt="" />
              <SearchInput 
                data-test-id="searchInput"
                placeholder="search friends" 
              />
            </SearchFriendBox>
            
            <SearchList>
              {filteredRecords.map((friend: any) => (
                <SearchItem key={friend.id} data-test-id="searchFriendsItem">
                  <SearchItemBox>
                    {this.renderSearchList(friend)}
                    <FriendProfileName>{friend.attributes.name}</FriendProfileName>
                  </SearchItemBox>
                  <ButtonContained data-test-id="navigateToChatButton" onClick={() => this.props.addMembersToTheGroup(friend.attributes.receipient_id)}>
                  {this.props.groupFormData.groupMembers.includes(friend.attributes.receipient_id) ? (
                    <img
                      src={checkMark}
                      alt="Add"
                      style={{
                        width: 24,
                        height: 24,
                        opacity: 1,
                        transition: 'opacity 0.3s ease, transform 0.3s ease',
                        transform: 'scale(1)',
                      }}
                    />
                  ) : (
                    "Add"
                  )}
                  </ButtonContained>
                </SearchItem>
            ))}
            </SearchList>

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <ButtonCancel
                variant="outlined"
                data-test-id="handleAddMemberCancel"
                onClick={() => this.setState({addMemberModalOpen: false})}
              >
                Cancel
              </ButtonCancel>
              <ButtonDone
                variant="outlined"
                data-test-id="handleAddMemberDone"
                onClick={this.addMemberToGroup}
              >
                Done
              </ButtonDone>
            </Box>
          </CreateChatModalContainer>
        </CreateChatModal> 
      )}
      </>
    )
  }

  renderGroupInfo = () => {
    const { showDataCount, groupAttachments } = this.state;
    const { receiverName, receiverPhoto, groupMembersCount, groupDescription } = this.props;
    const isAllDataShown = showDataCount >= groupAttachments.length;
    return (
      <Box sx={{ borderRight: `1px solid ${customTheme.palette.grey.light}`, width: "100%", overflow: 'hidden' }}>
        <Box sx={webStyles.headerViewContact}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
          >
            <img
              src={backButton}
              alt="backIcon"
              onClick={this.props.viewGroupCallback}
              data-test-id="handleGroupBackArrow"
            />
            <div style={{ ...CustomFontVariant.palette.font16700, lineHeight: "24px" }}>
              Group Details
            </div>
          </div>
        </Box>
        <Grid container spacing={6} style={{ paddingInline: 40, justifyContent: 'center' }}>
            <Grid item md={12} style={{display: 'flex', justifyContent: 'center', padding: '15px 24px 0px 24px'}}>
            <GroupImage src={receiverPhoto || ""} alt="GroupImage" />
          </Grid>
            <Grid item md={12} style={{display: 'grid', maxWidth: '80%'}}>
            {this.renderInputField("Group Name", "groupName", "group name", receiverName)}
            {this.renderInputField("Description", "groupDescription", "description", groupDescription)}
          </Grid>
          <Grid item md={12} style={{ maxWidth: '80%' }}>
            <Title1>Media:</Title1>
            <ContainerMain style={{ gridTemplateColumns: "repeat(auto-fill, minmax(118px, 1fr))", maxHeight: '100%' }}>
              {this.state.groupAttachments
                ?.slice(0, this.state.showDataCount)
                .map((url: any, index) => {
                  return <StyledImage
                    key={index}
                    src={url}
                    alt={`photo-${index}`}
                    data-test-id="handleOpenImageModal"
                  />
                })}
            </ContainerMain>
            {this.state.groupAttachments.length > 7 && <ShowMoreButton
              disabled={isAllDataShown}
              onClick={this.handleShowMore}
              data-test-id="handleShowMoreBtn"
            >
              show more
            </ShowMoreButton>}
          </Grid>
          <Grid item md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", maxWidth: '80%' }}>
            <div>
              <Typography style={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#1E293B",
                fontFamily: "Open Sans",
              }}>Group Members</Typography>
              <Typography style={{
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "24px",
                color: "#334155",
                fontFamily: "Open Sans",
              }}>{groupMembersCount > 9 ? groupMembersCount : `0${groupMembersCount}` } members</Typography>
            </div>
            {this.props.isGroupAdmin && <Button
              data-test-id={"addMemberDialog"}
              variant="contained"
              color="primary"
              onClick={() => this.setState({addMemberModalOpen: true})}
              style={{ padding: "10px", backgroundColor: '#EAE7DC', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', boxShadow: 'none' }}>
              <img src={PlusIcon} alt="Plus Icon" style={{ width: '24px', height: '24px' }} />
            </Button>}
            {this.renderAddMemberModal()}
          </Grid>
          {
            this.props.selectedGroupMember.map((item: any) => {
              return (
                <Grid item md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 24px 12px", maxWidth: '80%', borderBottom: '1px solid #ECEFF2' }}>
                  <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
                    {
                      item.attributes.photo ?
                      <img src={item.attributes.photo} alt={item.attributes.name?.charAt(0).toUpperCase()} style={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover' }} /> : 
                      <Box sx={webStyles.avatarCircle}>
                      {item.attributes.name?.charAt(0).toUpperCase()}
                    </Box>
                    }
                    <Typography style={{ fontWeight: '400', fontSize: '20px', lineHeight: '28px', fontFamily: "Open Sans", marginLeft: '20px' }}>{item.attributes.name}</Typography>
                    {item.attributes.admin && <AdminBadge>
                      <Typography style={{...CustomFontVariant.palette.font16400, lineHeight: '24px', paddingLeft: "8px"}}>admin</Typography>
                      </AdminBadge>}
                  </div>
                 {this.props.isGroupAdmin && item.attributes.account_id !== this.props.accountId && <Button
                    data-test-id={"moreVerIcon"}
                    variant="contained"
                    style={{background: 'white', boxShadow: "none", minWidth: '45px', padding: 0}}
                    onClick={(event) => this.handleGroupInfoMenu(event, item)}
                  >
                    <img src={moreVert} alt="morevert Icon" />
                  </Button>}
                  <CustomMenu
                      open={this.state.openGroupOptions}
                      onClose={this.handleGroupInfoMenuClose}
                      anchorEl={this.state.anchorElGroupOptions}
                      options={this.menuOptionsForGroupInfo}
                      position="right"
                      data-test-id="handleGroupMemberModal"
                      isGroupInfo={true}
                    />
                </Grid>
              )
            })
          }
           <Grid item md={12} style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: '14px 24px', maxWidth: '80%', margin: '40px 0px' }}>
            <Button
              data-test-id={"leaveChatRoomBtn"}
              variant="contained"
              color="primary"
              onClick={this.handleLeaveGroup}
              style={{...CustomFontVariant.palette.font16700, lineHeight: '24px', padding: "10px 16px", color: '#000000', backgroundColor: '#EAE7DC', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', marginRight: "45px", textTransform: "capitalize" }}>
                Leave Group
            </Button>
            <Button
              data-test-id={"createChatRoomBtn"}
              variant="contained"
              color="primary"
              onClick={this.handleReportModal}
              style={{ ...CustomFontVariant.palette.font16700, lineHeight: '24px', padding: "10px 16px", color: '#000000', backgroundColor: '#F87171', borderRadius: '8px', minWidth: 'auto', textDecoration: 'none', boxShadow: 'none', textTransform: "capitalize" }}>
               Report 
            </Button>
          </Grid>
        </Grid>
        <CustomModal
            open={this.state.makeAdmin}
            data-test-id="handleAdminModal"
            cancelButtonText="Cancel"
            doneButtonText="Assign"
            onCancel={this.handleAdmin}
            onDone={this.updateGroupAdmin}
            title="Assign Admin"
            subtitle="Are you sure you want to assign this user an admin role?"
          />
        <CustomModal
            open={this.state.removeMember}
            data-test-id="handleRemoveModal"
            cancelButtonText="Cancel"
            doneButtonText="Remove"
            onCancel={this.handleRemove}
            onDone={this.removeMemberToGroup}
            title="Remove"
            subtitle="Are you sure that you want to remove this user from the group?"
          />
          <CustomModal
            open={this.state.leaveGroup}
            data-test-id="handleLeaveGroupModal"
            cancelButtonText="Cancel"
            doneButtonText="Confirm"
            onCancel={this.handleLeaveGroup}
            onDone={this.leaveChatRoom}
            title="Leave Group"
            subtitle="Are you sure that you want to left this group?"
          />
      </Box>
    );
  };

  renderAttachmentPreview = () => {
    const isAttachments = this.state.attachments.length > 0;

    return (
      <>
        <Box sx={webStyles.attachmentsContainer}>
          {isAttachments && this.state.attachments.map((attachment, index) => (
            <Box key={index} sx={webStyles.attachmentsItem}>
              <div style={webStyles.attachmentsImageBox}>
                <div style={{
                  backgroundImage: `url(${attachment.imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                  borderRadius: '4px 0 0 4px'
                }} />
              </div>
              <div style={webStyles.attachmentsInner}>
              <p style={{fontWeight: 500, marginBottom: '8px'}}>{attachment.fileName}</p>
              <div style={{display: 'flex'}}>
                <p style={{opacity: '0.6', marginRight: '4px', marginTop: 0}}>{attachment.fileName.split('.').pop()?.toUpperCase()}</p>
                <p style={{opacity: '0.6', marginTop: 0}}>{(attachment.fileSize / 1024).toFixed(2)} KB</p>
                </div>
              </div>
              <button
                data-test-id="removeAttachmentFileButton"
                onClick={() => this.removeAttachment(index)}
                style={{
                  ...webStyles.removeAttachmentButton,
                  position: 'absolute'
                }}
              >
                x
              </button>
            </Box>
          ))}
        </Box>
      </>
    )
  }

  renderCreateGroupView = () => {
    return (
      <>
        <Box sx={webStyles.headerCreateGroupView} data-test-id="createGroupView">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
          >
            <img
              src={backButton}
              alt="backIcon"
              onClick={this.props.handleCloseCreateGroupView}
              data-test-id="handleBackArrow"
            />
            <div style={{ ...CustomFontVariant.palette.font16700, lineHeight: "24px" }}>
              New Group Chat
            </div>
          </div>
        </Box>

        <Box sx={webStyles.createGroupViewInner}>
          {this.renderSelectGroupAvatarCircle()}

          <div style={{ marginBottom: 40 }}>
            {this.renderInputField("Group Name", "groupName", "group name")}
            {this.renderInputField("Description", "groupDescription", "description")}
          </div>

          <SearchFriendBox>
            <SearchIcon src={searchIcon} alt="" />
            <SearchInput
              data-test-id="searchInput"
              placeholder="search friends and groups"
                sx={{padding: 0}}
              value={this.props.searchFriendForGroupInput}
              onChange={this.props.handleSearchFriendsForGroupInputChange}
            />
          </SearchFriendBox>

            <SearchList style={{maxHeight: '300px'}}>
            {this.props.searchFriendsListForGroup.map(friend => (
              <SearchItem key={friend.id} data-test-id="searchFriendsItem">
                <SearchItemBox>
                  {friend.attributes.photo ? (
                    <FriendProfileImg src={friend.attributes.photo} alt={friend.attributes.name || ""} data-test-id="friendPhoto" />
                  ) : (
                    <Box sx={webStyles.avatarCircle} data-test-id="friendCircleWithLetter">
                      {friend.attributes.name?.charAt(0).toUpperCase()}
                    </Box>
                  )}
                  <FriendProfileName data-test-id="friendName">{friend.attributes.name}</FriendProfileName>
                </SearchItemBox>
                <ButtonContainedForGroup
                  data-test-id="addFriendToGroup"
                  onClick={() => this.props.addMembersToTheGroup(friend.attributes.receipient_id)
                  }>
                  {this.props.groupFormData.groupMembers.includes(friend.attributes.receipient_id) ? (
                    <img
                      src={checkMark}
                      alt="Add"
                      style={{
                        width: 24,
                        height: 24,
                        opacity: 1,
                        transition: 'opacity 0.3s ease, transform 0.3s ease',
                        transform: 'scale(1)',
                      }}
                    />
                  ) : (
                    "Add"
                  )}
                </ButtonContainedForGroup>
              </SearchItem>
            ))}
          </SearchList>

            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
            <CancelCreateGroupButton
              variant="outlined"
              data-test-id="handleCreateGroupCancel"
              onClick={this.props.handleCloseCreateGroupView}
            >
              Cancel
            </CancelCreateGroupButton>
            <CreateNewGroupButton
              data-test-id="handleCreateGroupChat"
              onClick={this.props.handleCreateGroupChat}
            >
              Create New Group
            </CreateNewGroupButton>
          </Box>
        </Box>
      </>
    )
  }

  renderSelectGroupAvatarCircle = () => {
    return (
      <Box sx={webStyles.selectGroupAvatarBox}>
        <label htmlFor="group-image-upload">
          <input
            id="group-image-upload"
            data-test-id="groupImageUpload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={this.handleChangeGroupAvatar}
          />
          <Box
            component="div"
            sx={webStyles.selectGroupAvatarCircle}
          >
            {this.props.groupFormData.selectedAvatarImage ? (
              <img
                src={this.props.groupFormData.selectedAvatarImage}
                alt="Group"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column',
              }}>
                <img
                  src={uploadIcon}
                  alt="Add"
                  style={{
                    width: 24,
                    height: 24,
                    marginBottom: '8px',
                  }}
                />
                <span style={{fontFamily: 'Open Sans', fontSize: '12px'}}>Add images</span>
              </div>
            )}
          </Box>
        </label>
      </Box>
    )
  }

  renderInputField = (label: string, stateKey: keyof Pick<GroupFormData, 'groupName' | 'groupDescription'>, placeholder: string, value?:(string | null)) => {
    return (
      <div style={{marginBottom: 16, width: '100%'}}>
        <div style={{ ...CustomFontVariant.palette.font14700, lineHeight: "22px", color: '#334155', marginBottom: 4 }}>{label}</div>
        <input
          type="text"
          placeholder={placeholder}
          value={this.props.groupFormData[stateKey] || value || ""}
          onChange={(e) => this.props.handleGroupFormChange(stateKey, e.target.value)}
          style={webStyles.inputForGroup}
          data-test-id="setUpwDataForNewGroup"
        />
      </div>
    );
  };

  getPageToRender = () => {
    const { isCreateGroupViewOpen, viewContact, isGroupInfoVisible } = this.props;

    const pages = {
      createGroup: this.renderCreateGroupView,
      viewContact: this.renderViewContact,
      isGroupInfoVisible: this.renderGroupInfo,
      default: this.renderChatView,
    };

    if (isCreateGroupViewOpen) return pages.createGroup();
    if (viewContact) return pages.viewContact();
    if (isGroupInfoVisible) return pages.isGroupInfoVisible();

    return pages.default();
  };

  renderHeader = () => {
    const { receiverName, receiverPhoto, chatType, groupMembersCount } = this.props;

    return (
      <Box sx={webStyles.header}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ marginRight: '6px' }}>
            {receiverPhoto ? (
              <img
                src={receiverPhoto}
                alt={receiverName || "name"}
                style={{ width: '44px', height: '44px', borderRadius: '50%', objectFit: 'cover' }}
              />
            ) : (
              <Box sx={webStyles.avatarCircle} data-test-id="avatarCircle">
                {receiverName ? receiverName.charAt(0).toUpperCase() : ""}
              </Box>
            )}
          </Box>
          <Box>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '22px',
                color: '#0F172A',
              }}
            >
              {receiverName}
            </Typography>
            {chatType === "group" && (
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  lineHeight: '22px',
                  color: '#0F172A',
                }}
              >
                {groupMembersCount} members
              </Typography>
            )}
          </Box>
        </Box>
        {this.renderDropDownMenu()}
      </Box>
    );
  };

  renderLoader = () => {
    return(
    <div
      style={{
        margin:"50px auto"
      }}
    >
      <CircularProgress size={25} color={customTheme.palette.grey.primary} />
    </div>
    )
  };

  renderChatView = () => {
    const {loading} =this.state;
    return (
      <>
      {loading?
        this.renderLoader() :

      <>
      
        {this.renderHeader()}

        <Box sx={webStyles.innerContainer}>
          {this.renderMessages()}

          {!this.props.isBlocked &&
            <Box sx={webStyles.inputContainer}>
              <Box sx={webStyles.iconContainer}>
                <label htmlFor="fileInput">
                  <img src={uploadFile} alt="Upload File" style={webStyles.iconStyle} />
                </label>
                <input
                  data-test-id="attachFile"
                  id="fileInput"
                  type="file"
                  hidden
                  onChange={this.handleFileChange}
                />
                <img
                  data-test-id="attachEmoji"
                  src={emojiIcon}
                  alt="Emoji"
                  style={webStyles.iconStyle}
                  onClick={this.toggleEmojiPicker}
                />
              </Box>
              <InputBase
                data-test-id="inputMessage"
                placeholder={`Message ${this.props.receiverName}`}
                sx={webStyles.messageInput}
                value={this.state.message}
                onChange={(event) => this.handleMessageChange(event.target.value)}
                onKeyPress={this.sendMessageOnKeyEnter}
              />
              <Button onClick={this.handleSendMessage} style={webStyles.sendMessageButton} data-test-id="sendMessageButton">
                <img src={sendMessage} alt="Send message" style={{width: '24px', height: '24px'}}/>
              </Button>

              {this.state.isEmojiPickerOpen &&
                <Box sx={webStyles.emojiPickerContainer}>
                  <EmojiPicker onEmojiClick={this.handleEmojiSelect} />
                </Box>
              }

              {this.state.messageError && (
                <Box sx={webStyles.messageError} data-test-id="errorMessage">
                  {this.state.messageError}
                </Box>
              )}
            </Box>
          }

          {this.renderAttachmentPreview()}
        </Box>
      </>
      }
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={webStyles.container}>

            {this.getPageToRender()}

          </Box>
          <CustomModal
            open={this.state.unfriend}
            data-test-id="handleUnfriendModal"
            cancelButtonText="Cancel"
            doneButtonText="Unfriend"
            onCancel={this.handleUnfriendModal}
            onDone={this.unfriendUser}
            title="Unfriend"
            subtitle={`Are you sure you want to unfriend ${this.props.receiverName}?`}
          />
          <CustomModal
            open={this.state.blockAccount}
            data-test-id="handleBlockAccountModal"
            cancelButtonText="Cancel"
            doneButtonText="Block"
            onCancel={this.handleBlockAccountModal}
            onDone={this.blockFriend}
            title="Block Account"
            subtitle={`Are you sure you want to block ${this.props.receiverName}?`}
          />
          <CustomModal
            open={this.state.reportModalOpen}
            data-test-id="handleReportModal"
            cancelButtonText="Cancel"
            doneButtonText="Submit"
            onCancel={this.handleReportModal}
            onDone={this.handleReportModalDone}
            onShowCloseIcon={this.handleReportModal}
            title="Report"
            subtitle={`You are reporting ${this.props.receiverName} for:`}
          >
            <div
              style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                marginTop: 24,
              }}
            >
              <Label>Reason</Label>
              <CustomFlagDropdown isOpen={this.state.openReportMenu}>
                <input
                  type="text"
                  placeholder="select reason"
                  value={this.state.reportReason}
                  onClick={this.handleMenuItem}
                  data-test-id="handleMenuItem"
                />
                <KeyboardArrowDownIcon className="downIcon" />
                <Box className="flagItem">
                  {this.state.reportData.map((item, index) => (
                    <TypographyItem
                      key={index}
                      onClick={() => this.handleSelectReason(item)}
                      data-test-id="handleSelectReason"
                    >
                      {item.reason}
                    </TypographyItem>
                  ))}
                </Box>
              </CustomFlagDropdown>
            </div>
          </CustomModal>
          <Toast
            open={this.state.blockToastOpen}
            message={this.state.blockToastMsg}
            severity={"success"}
            onClose={this.handleCloseBlockToast}
            duration={3000}
            position={{ vertical: "top", horizontal: "center" }}
            data-test-id="handleCloseBlockToast"
          />
          <Toast
            open={this.state.blockErrorToastOpen}
            message={this.state.blockToastMsg}
            severity={"error"}
            onClose={this.handleCloseBlockToast}
            duration={3000}
            position={{ vertical: "top", horizontal: "center" }}
            data-test-id="handleCloseBlockErrorToast"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: '1px solid #CBD5E1',
    height: '100%',
  },
  header: {
    display: "flex",
    width: '100%',
    borderBottom: "1px solid #CBD5E1",
    justifyContent: "space-between",
    padding: '17px 48px',
    alignItems: "center",
    backgroundColor: '#FFFCF3',
    boxSizing: 'border-box',
    marginBottom: '35px'
  },
  headerButtonView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  avatarCircle: {
    fontFamily: 'Open Sans',
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    backgroundColor: '#EAE7DC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#000',
  },
  avatarSquare: {
    fontFamily: 'Open Sans',
    backgroundColor: '#EAE7DC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '50px',
    fontWeight: '400',
    color: '#000',
    maxWidth: "320px",
    width: "100%",
    aspectRatio:"1 / 1",
    borderRadius: "50px",
    objectFit: "cover",
  },
  innerContainer: {
    padding: '0 48px',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '40px'
  },
  inputContainer: {
    position: 'relative',
    padding: '6px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '20px'
  },
  iconContainer: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '6px',
    paddingRight: '0px'
  },
  iconStyle: {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
  messageInput: {
    fontFamily: 'Open Sans',
    flex: 1,
    padding: '6px 12px',
    border: 'none',
    '&::placeholder': {
      fontSize: '16px',
      color: '#64748B',
    },
  },
  sendMessageButton: {
    padding: '10px',
    backgroundColor: '#EAE7DC',
    borderRadius: '8px',
    minWidth: 'auto'
  },
  messageError: {
    fontFamily: 'Open Sans',
    position: 'absolute',
    fontSize: '14px',
    color: 'red',
    bottom: '-30px',
    left: '0'
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    width: '100%',
  },
  attachmentsItem: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid #3D3C401A',
    minWidth: '320px',
    height: '115px',
    marginRight: '10px',
    marginBottom: '10px',
    position: 'relative'
  },
  attachmentsImageBox: {
    borderRight: '1px solid rgba(0, 0, 0, 0.15)',
    height: '100%',
    width: '100px',
    overflow: 'hidden',
    borderRadius: '4px 0 0 4px'
  },
  attachmentsInner: {
    fontSize: '13px',
    paddingLeft: '15px'
  },
  removeAttachmentButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
    top: 0, right: 0,
    borderRadius: '0 4px 0 4px',
    backgroundColor: '#EAE7DC',
    height: '28px',
    width: '28px',
    fontWeight: 700
  },
  messageBoxContainer: {
    display: "flex",
    marginBottom: '16px',
  },
  emojiPickerContainer: {
    position: "absolute",
    left: 0,
    bottom: '60px'
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewModalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    width: '80%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  previewImage: {
    width: "auto",
    height: "180px",
  },
  previewImageGroup: {
    width: "auto",
    height: "96px",
  },
  previewInput: {
    marginLeft: 10,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  previewModalButtons: {
    margin: 10,
    display: "flex",
    flexDirection: "row",
  },
  headerViewContact: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #CBD5E1",
    justifyContent: "space-between",
    padding: "17px 16px",
    alignItems: "center",
    backgroundColor: "#FFFCF3",
    boxSizing: "border-box",
    marginBottom: "35px",
  },
  headerCreateGroupView: {
    display: "flex",
    width: "100%",
    borderBottom: "1px solid #E2E8F0",
    justifyContent: "space-between",
    padding: "17px 48px",
    alignItems: "center",
    boxSizing: "border-box",
  },
  createGroupViewInner: {
    padding: '16px 68px 68px 68px',
    width: '100%',
    boxSizing: "border-box",
  },
  selectGroupAvatarBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '16px',
  },
  selectGroupAvatarCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '132px',
    height: '132px',
    borderRadius: '50%',
    backgroundColor: '#FFFCF3',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #EAE7DC',
  },
  inputForGroup: {
    width: "100%",
    padding: "10px 8px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    '&::placeholder': {
      ...CustomFontVariant.palette.font16400,
      color: '#94A3B8',
    },
  },
};

const ProfileImage = styled("img")({
  maxWidth: "320px",
  width: "100%",
  aspectRatio:"1 / 1",
  borderRadius: "50px",
  objectFit: "cover",
});

const GroupImage = styled("img")({
  maxWidth: "132px",
  width: "100%",
  borderRadius: "100px",
  border: '2px solid #EAE7DC'
});

const Title = styled("div")({
  ...CustomFontVariant.palette.font20700,
  fontFamily: "Inter",
  lineHeight: "28px",
  marginTop: 16,
});

const Title1 = styled("div")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  fontFamily: "Inter",
  marginBottom: 16,
});

const Title2 = styled("div")({
  ...CustomFontVariant.palette.font14700,
  margin: "16px 0px",
  fontFamily: "Inter",
  lineHeight: "22px",
});

const Description = styled("div")({
  ...CustomFontVariant.palette.font16400,
  lineHeight: "24px",
  marginTop: 16,
});

const ButtonContained = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  border: "none",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: customTheme.palette.grey.primary,
  },
  "&:disabled": {
    backgroundColor: '#E2E8F0',
    color: "#000000"
   }
});

const ButtonContainedRed = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.red.secondary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  border: "none",
  borderRadius: 8,
  '&:hover': {
    backgroundColor: customTheme.palette.red.secondary,
  }
});

const ContainerMain = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(104px, 1fr))",
  columnGap: "16px",
  rowGap: "12px",
  width: "100%",
  marginTop: 12,
  overflow: "auto",
  maxHeight: 192,
});

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
  objectFit: "cover",
  aspectRatio: "4 / 3",
});

const Parent = styled("div")({
  display: "flex",
  gap: 16,
  alignItems: "center",
  marginBottom: 12,
});

const Img = styled("img")({
  width: "44px",
  height: "44px",
  objectFit: "cover",
  borderRadius: 12,
});

const ShowMoreButton = styled(Box)(({ disabled }: any) => ({
  marginTop: 10,
  color: customTheme.palette.grey.extraLight,
  textTransform: "none",
  display: disabled ? "none" : "block",
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  cursor: "pointer",
}));

const Label = styled("label")({
  ...CustomFontVariant.palette.font14700,
  lineHeight: "22px",
  color: customTheme.palette.grey.secondary,
});

const CustomFlagDropdown = styled(Box)(({ isOpen }: any) => ({
  position: "relative",
  "& .downIcon": {
    position: "absolute",
    right: 10,
    top: 10,
  },
  "& input": {
    width: "100%",
    height: 44,
    border: `1px solid ${customTheme.palette.grey.light}`,
    borderRadius: 8,
    padding: "10px 28px 10px 8px",
    "&::placeholder": {
      color: "gray",
    },
    "&:focus-visible": {
      border: `1px solid ${customTheme.palette.grey.light}`,
      outline: "none",
    },
  },
  "& .flagItem": {
    position: "absolute",
    left: 0,
    top: 44,
    boxShadow: "0px 2px 4px 0px #00000026",
    borderRadius: 8,
    display: isOpen ? "block" : "none",
    background: customTheme.palette.white.main,
    maxWidth: 452,
    width: "100%",
    padding: 10,
    zIndex: 9999999,
    "& p": {
      borderRadius: 8,
      padding: "8px 16px",
      fontSize: 16,
      cursor: "pointer",
    },
    "& p:hover": {
      backgroundColor: customTheme.palette.grey.primary,
    },
    maxHeight: "30vh",
    overflowY: "auto",
  },
}));

const TypographyItem = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  lineHeight: "24px",
  color: customTheme.palette.grey.bold,
});

const EllipseMsg = styled("div")({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 200,
  ...CustomFontVariant.palette.font14400,
  fontFamily: "Inter",
  lineHeight: "22px"
});


const FriendProfileName = styled("div")({
  ...CustomFontVariant.palette.font20400,
  lineHeight: "28px",
  paddingLeft: '16px',
});

const ButtonContainedForGroup = styled(Button)({
  padding: "10px 40px",
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  border: "none",
  width: '112px',
  height: '44px',
  borderRadius: '8px',
});

const CancelCreateGroupButton = styled(Button)({
  width: '106px',
  height: '44px',
  color: `${customTheme.palette.black.primary}`,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  borderColor: `${customTheme.palette.black.primary}`,
  marginRight: '40px',
  borderRadius: '8px',
})

const CreateNewGroupButton = styled(Button)({
  width: '180px',
  height: '44px',
  backgroundColor: customTheme.palette.grey.primary,
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16700,
  lineHeight: "24px",
  textTransform: "none",
  border: "none",
  padding: 0,
  borderRadius: '8px',
})

const CreateChatModal = styled("div")({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const CreateChatModalContainer = styled("div")({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "40px",
  borderRadius: "24px",
  margin: "100px auto",
  position: "relative",
});

const CreateChatTitle = styled("h3")({
  fontFamily: 'Open Sans',
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 700,
  margin: 0,
  marginBottom: '30px',
});

const ButtonCancel = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}`,
  ...CustomFontVariant.palette.font16700,
  lineHeight: '24px',
  textTransform: "none",
  borderColor: `${customTheme.palette.black.primary}`,
  borderRadius: '8px',
});

const ButtonDone = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}`,
  ...CustomFontVariant.palette.font16700,
  lineHeight: '24px',
  textTransform: "none",
  borderColor: `${customTheme.palette.black.primary}`,
  borderRadius: '8px',
  background: '#EAE7DC',
  border: 'none',
  marginLeft: '15px',
  '&:hover': {
  background: '#EAE7DC',
  border: 'none',
  }
});

const AdminBadge = styled('div')({
  width: "64px",
  height: "28px",
  borderRadius: "50px",
  background: "#EAE7DC",
  padding: "2px 8px",
  display: "flex",
  alignContent: "center",
  marginLeft: "15px"
})
// Customizable Area End
