import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import { styled } from "@material-ui/core";
import { like, unliked } from "./assets";
// Customizable Area End

import Likeapost2Controller, {
  Props,
  configJSON,
} from "./Likeapost2Controller";

export default class Likeapost2 extends Likeapost2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ActionCount
          onClick={() => this.handleAction()}
          data-test-id="handleAction"
        >
          <Count>{this.props.likeCount}</Count>
          <Img
            src={this.props.currentUserLike ? like : unliked}
            alt="actionIcon"
          />
        </ActionCount>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ActionCount = styled("div")({
  display: "flex",
  gap: 4,
  cursor: "pointer",
  alignItems: "center",
});

const Img = styled("img")({
  cursor: "pointer",
});

const Count = styled("div")({
  ...CustomFontVariant.palette.font16700,
  lineHeight: '24px',
});

// Customizable Area End
